import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { PageLoadingStyle } from './styles';

function PageLoading() {
  return (
    <PageLoadingStyle>
      <div>
        <div><LoadingOutlined style={{ fontSize: '48px', color: '#1890ff', opacity: 0.45 }} /></div>
        <div style={{ fontSize: '14px', color: '#1890ff', marginTop: '12px' }}>加载中...</div>
      </div>
    </PageLoadingStyle>
  );
}

export default PageLoading
