import { cloneDeep, indexOf } from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'

/**
 * setCookie
 *
 * @export
 * @param {string} name
 * @param {string} value
 * @param {number} [expiredays=365]
 */
export function setCookie(name: string, value: string, expiredays = 365) {
  const exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  document.cookie = `${name}=${escape(value)};expires=${exdate.toUTCString()}`
}

/**
 * getCookie
 *
 * @export
 * @param {string} name
 * @returns
 */
export function getCookie(name: string) {
  if (document.cookie.length > 0) {
    let cStart = document.cookie.indexOf(name + '=')
    if (cStart !== -1) {
      cStart = cStart + name.length + 1
      let cEnd = document.cookie.indexOf(';', cStart)
      if (cEnd === -1) {
        cEnd = document.cookie.length
      }
      return unescape(document.cookie.substring(cStart, cEnd))
    }
  }
  return ''
}

/**
 * clearCookie
 *
 * @export
 * @param {string} name
 */
export function clearCookie(name: string) {
  setCookie(name, '')
}

/**
 * 从url获取参数
 *
 * @export
 * @param {string} name
 * @returns {string}
 */
export function queryURL(name: string): string {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const result = window.location.search.substr(1).match(reg)
  if (result !== null) {
    return decodeURI(result[2])
  }
  return null
}

/**
 * 数组查询
 *
 * @export
 * @template T
 * @param {any[]} array
 * @param {string} key
 * @param {string} [keyAlias='key']
 * @returns {T}
 */
export function queryArray<T>(array: any[], key: string, keyAlias = 'key'): T {
  if (!(array instanceof Array)) {
    return null
  }
  const item = array.filter(a => a[keyAlias] === key)
  if (item.length) {
    return item[0]
  }
  return null
}

/**
 * 数组格式转树状结构
 *
 * @export
 * @template T
 * @param {any[]} array
 * @param {string} [id='id']
 * @param {string} [pid='pid']
 * @param {string} [children='children']
 * @returns {T[]}
 */
export function arrayToTree<T>(array: any[], id = 'id', pid = 'pid', children = 'children'): T[] {
  const data = cloneDeep(array)
  const result = []
  const hash = {}
  data.forEach((_, index) => {
    hash[data[index][id]] = data[index]
  })
  data.forEach(item => {
    const hashVP = hash[item[pid]]
    if (hashVP) {
      if (!hashVP[children]) {
        hashVP[children] = []
      }
      hashVP[children].push(item)
    } else {
      result.push(item)
    }
  })
  return result
}

export function windowOpen(url) {
  const id = `alink-${new Date().getTime()}`
  const aDom = document.createElement('a')
  aDom.setAttribute('href', url)
  aDom.setAttribute('target', '_blank')
  aDom.setAttribute('id', id)
  if (!document.getElementById(id)) {
    document.body.appendChild(aDom)
  }
  aDom.click()
}

/**
 * 获取地址栏参数
 */
export const getQueryString = (name: string) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const url = window.location.href.split('?')
  if (url.length < 2) { return null }
  const r = url[1].match(reg)
  if (r !== null) { return decodeURIComponent(r[2]) }
  return null
}

/**
 * 获取缓存的用户信息
 **/
export const getCacheUserInfo = () => {
  const uInfo = sessionStorage.getItem('userInfo')
  return (uInfo == null) ? uInfo : JSON.parse(sessionStorage.getItem('userInfo'))
}

/**
 * str是否在数组中
 * @param Arr 源数组
 * @param str 查找的字符串
 **/
export const inArray = (Arr, str) => {
  return indexOf(Arr, str)
}

/**
 * 浏览器控制台输出彩色信息提示
 * author: xufuzi
 */
interface IColorMsg {
  type?: 'success' | 'info' | 'warn' | 'error' | 'customize'
  title?: string
  content: string
  colors?: {
    titleBackGroundColor: string;
    textColor: string;
    contentBackGroundColor: string;
    contentColor: string;
  }
}
export const colorMsg = (logInfo: IColorMsg) => {
  let { type, title, content, colors } = logInfo
  if (title === '' && content === '') {
    return
  }
  const types = ['success', 'info', 'warn', 'error', 'customize']
  const defColors = {
    success: {
      titleBackGroundColor: '#52c41a',
      textColor: '#fff',
      contentBackGroundColor: '#f6ffed',
      contentColor: '#52c41a',
    },
    info: {
      titleBackGroundColor: '#1890ff',
      textColor: '#fff',
      contentBackGroundColor: '#e6f7ff',
      contentColor: '#1890ff',
    },
    warn: {
      titleBackGroundColor: '#faad14',
      textColor: '#fff',
      contentBackGroundColor: '#fffbe6',
      contentColor: '#faad14',
    },
    error: {
      titleBackGroundColor: '#ff4d4f',
      textColor: '#fff',
      contentBackGroundColor: '#fff2f0',
      contentColor: '#ff4d4f',
    },
  }

  if (types.indexOf(type) === -1) {
    type = 'info'
  }
  if (!title) { title = '信息' }

  let outStrTemp = []
  if (type === 'customize') {
    outStrTemp = ['%c '.concat(title, ' %c ').concat(content, ' '),
    `padding:2px 2px;background:${colors.titleBackGroundColor};color:${colors.textColor};border-radius: 2px;`,
    `color:${colors.contentColor};font-size:12px;background:${colors.contentBackGroundColor};padding:2px 6px 2px 0;border-radius: 0px 2px 2px 0;`]
  } else {
    outStrTemp = ['%c '.concat(title, ' %c ').concat(content, ' '),
    `padding:2px 2px;background:${defColors[type].titleBackGroundColor};color:${defColors[type].textColor};border-radius: 2px;`,
    `color:${defColors[type].contentColor};font-size:12px;background:${defColors[type].contentBackGroundColor};padding:2px 6px 2px 0;border-radius: 0px 2px 2px 0;`]
  }

  window.console && 'function' === typeof window.console.log && (console).log.apply(console, outStrTemp)
}

/** 随机数 */
export const randomNumber = (end: number = 0, start: number = 0) => {
  return Math.floor(Math.random() * end) + start
}


export const useSize = (): { x: number; y: number } => {
  const history = useHistory();
  const isCurrent = window.location.pathname === '/screen';
  const [size, setSize] = React.useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  const onResize = React.useCallback(() => {
    const newSize = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
    setSize(newSize);
  }, []);

  React.useEffect(() => {
    if (!isCurrent) {
      window.removeEventListener('resize', onResize, false);
    } else {
      window.addEventListener('resize', onResize, false);
    }
    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [history.location.pathname]);

  return {
    y: size.height,
    x: size.width,
  };
};