import logo from './logo.svg';
import './App.css';
import React from 'react';
import { AppStyle } from './App.styles';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable'
import PageLoading from '@components/PageLoading'

interface IState {
  isLogin: boolean
}
const LayoutView = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './views/layoutView/index'),
  loading: PageLoading
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './views/login'),
  loading: PageLoading
});

const AppWrapper = (props: any) => <AppStyle >{props.children}</AppStyle>;

class AppRouter extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <AppWrapper>
        <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={LayoutView} />
        </Switch>
      </AppWrapper>
    )
  }
}
export default AppRouter;
