// @ts-nocheck

import 'core-js/fn/object/entries'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { colorMsg } from '@utils/index'
import App from './App'
import AppConfig from './env'
import './common.scss'
// import UserContexts from './contexts/user'
moment.locale('zh-cn')
const env: string = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
// 去掉生产环境log
if (AppConfig[env].API_BASE_URL === 'prod') {
  // tslint:disable-next-line: only-arrow-functions
  window.console.log = function () {}
} else {
  // 非生产环境 - 环境信息提示
  colorMsg({
    type: 'success',
    content: `当前环境: ${AppConfig[env].API_BASE_URL} 数据接口: ${AppConfig[env].API_BASE_URL, env}`,
  })
}

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
  , document.getElementById('root') as HTMLElement
);
