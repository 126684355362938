import styled from 'styled-components';

export const AppStyle = styled.div`
  height: 100%;
  .appWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.intlSelect:global.ant-select {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 100px;
}

td {
  word-break: break-all;
}

:global {
  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input-wrap input {
      padding-right: 0;
    }
  }
}

`
