/**
 *  各环境数据接口配置
 */
module.exports = {
  "mock": {
    "API_BASE_URL": "https://api.meimiaoshijie.top",
    "WS_BASE_URL": "/",
    "STATIC_DOMAIN": '/',
  },
  "debug": {
    "API_BASE_URL": "https://api.meimiaoshijie.top", // 联调
    "WS_BASE_URL": "/", // 联调
    "STATIC_DOMAIN": '/', 
  },
  "test": {
    "API_BASE_URL": "https://api.meimiaoshijie.top", // 联调
    "WS_BASE_URL": "/", // 联调
    "STATIC_DOMAIN": '/', 
  },
  "prod": {
    "API_BASE_URL": "https://api.meimiaoshijie.top",
    "WS_BASE_URL": "/",
    "STATIC_DOMAIN": '/', 
  }
}
