import styled from 'styled-components';

export const PageLoadingStyle = styled.div`
  width: 100%;
  min-height: 800px;
  display: flex;
  // flex: 1;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
